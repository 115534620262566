::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background: transparent !important;
}

::-webkit-scrollbar-thumb {
	background: #e5e5e5;
	border-radius: 2px;
}

.custom-slider-video {
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	width: 100%; /* Specified width */
	height: 4px; /* Specified height */
	background: transparent; /* Transparent background to customize the track */
	outline: none; /* Remove outline */
	--video-progress-value: 0%;
}

.custom-slider-video::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	border-radius: 2px;
	background: linear-gradient(to right, #016dea var(--video-progress-value), #ddd var(--video-progress-value));
}

.custom-slider-video::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
	margin-top: -5px; /* Adjust thumb position to align with the track */
}

.custom-slider-video::-moz-range-track {
	width: 100%;
	height: 4px;
	background: #ddd; /* Background color for the track */
	border-radius: 2px;
}

.custom-slider-video::-moz-range-progress {
	background: #016dea; /* Color for the filled part */
	border-radius: 2px;
}

.custom-slider-video::-moz-range-thumb {
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
}

.custom-slider-video::-ms-track {
	width: 100%;
	height: 4px;
	background: transparent; /* Transparent background to customize the track */
	border-color: transparent; /* Hide default border color */
	color: transparent; /* Hide default color */
}

.custom-slider-video::-ms-fill-lower {
	background: #016dea; /* Color for the filled part */
	border-radius: 2px;
}

.custom-slider-video::-ms-fill-upper {
	background: #ddd; /* Background color for the track */
	border-radius: 2px;
}

.custom-slider-video::-ms-thumb {
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
}

.custom-slider-audio {
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	width: 100%; /* Specified width */
	height: 4px; /* Specified height */
	background: transparent; /* Transparent background to customize the track */
	outline: none; /* Remove outline */
	--audio-progress-value: 0%;
}

.custom-slider-audio::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	border-radius: 2px;
	background: linear-gradient(to right, #016dea var(--audio-progress-value), #ddd var(--audio-progress-value));
}

.custom-slider-audio::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
	margin-top: -5px; /* Adjust thumb position to align with the track */
}

.custom-slider-audio::-moz-range-track {
	width: 100%;
	height: 4px;
	background: #ddd; /* Background color for the track */
	border-radius: 2px;
}

.custom-slider-audio::-moz-range-progress {
	background: #016dea; /* Color for the filled part */
	border-radius: 2px;
}

.custom-slider-audio::-moz-range-thumb {
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
}

.custom-slider-audio::-ms-track {
	width: 100%;
	height: 4px;
	background: transparent; /* Transparent background to customize the track */
	border-color: transparent; /* Hide default border color */
	color: transparent; /* Hide default color */
}

.custom-slider-audio::-ms-fill-lower {
	background: #016dea; /* Color for the filled part */
	border-radius: 2px;
}

.custom-slider-audio::-ms-fill-upper {
	background: #ddd; /* Background color for the track */
	border-radius: 2px;
}

.custom-slider-audio::-ms-thumb {
	width: 14px; /* Specified width of thumb */
	height: 14px; /* Specified height of thumb */
	background: #016dea; /* Specified color of thumb */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%; /* Round thumb */
}
